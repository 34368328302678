<template>
  <div>
    <div class="header">
      <img
        class="img1"
        src="https://www.d-shang.com/image/xgw/sy/LOGO.png"
        alt=""
      />
      <ul>
        <li @click="route('/index')"><a>首页</a></li>
        <li @click="route('/space')" class="headergyds3li">
          <a href="">空间灵感</a>
          <div class="headergyds3">
            <ul>
              <li @click="handleMouseEnter1vuex()">
                <a style="border: 0" href="#ulmd">
                  <img
                    src="https://www.d-shang.com/image/xgw/kjlg/1.png"
                    alt=""
                  />
                  <a style="border: 0">集成吊顶</a>
                </a>
              </li>
              <li @click="handleMouseEnter2vuex()">
                <a style="border: 0" href="#ulmd">
                <img
                  src="https://www.d-shang.com/image/xgw/kjlg/2.png"
                  alt=""
                />
                <a style="border: 0" href="#ulmd">集成墙面</a>
                </a>
              </li>
              <li @click="handleMouseEnter3vuex()">
                <a style="border: 0" href="#ulmd">
                <img
                  src="https://www.d-shang.com/image/xgw/kjlg/3.png"
                  alt=""
                />
                <a style="border: 0" href="#ulmd">定制门柜</a>
                </a>
              </li>
              <li @click="handleMouseEnter4vuex()">
                <a style="border: 0" href="#ulmd">
                <img
                  src="https://www.d-shang.com/image/xgw/kjlg/4.png"
                  alt=""
                />
                <a style="border: 0" href="#ulmd">阳台生活</a>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li @click="route('/about')" class="headergydsli">
          <a href="">关于顶上</a>
          <div class="headergyds">
            <ul>
              <li class="hoverli1">
                <router-link
                  class="a11"
                  :to="{ name: 'About', hash: '#gydiv1md' }"
                >
                  企业简介</router-link
                >
              </li>
              <li class="hoverli1">企业文化</li>
              <li class="hoverli1">光辉历程</li>
              <li class="hoverli1">企业荣誉</li>
            </ul>
          </div>
        </li>
        <li class="headergyds2li">
          <a @click="route('/news')" href="">新闻中心</a>
          <div class="headergyds2">
            <ul>
              <li class="hoverli1" @click="route('/news')">
                <a style="border: 0" href="#listtop11">品牌资讯</a>
              </li>
              <li class="hoverli1" @click="route('/news')">
                <a style="border: 0" href="#listtop11">活动咨询</a>
              </li>
              <li class="hoverli1" @click="route('/news')">
                <a style="border: 0" href="#listtop11">产品咨询</a>
              </li>
            </ul>
          </div>
        </li>
        <li @click="route('/join')"><a href="">招商加盟</a></li>
        <li @click="route('/contact')"><a href="">联系我们</a></li>
      </ul>
      <div class="login">
        <img
          class="img2"
          src="https://www.d-shang.com/image/xgw/sy/login.png"
          alt=""
        />
        <a href="https://www.dingshangjc.com:6090/#/login" target="_blank"
          >经销商登录</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import md5 from "js-md5";
export default {
  name: "HoMe",
  data() {
    return {
      kjl: [],
      sign: [],
    };
  },

  methods: {
    handleMouseEnter1vuex(){
    this.$store.commit("handleMouseEnter1");
    },
    handleMouseEnter2vuex(){
    this.$store.commit("handleMouseEnter2");
    },
    handleMouseEnter3vuex(){
    this.$store.commit("handleMouseEnter2");
    },
    handleMouseEnter4vuex(){
    this.$store.commit("handleMouseEnter2");
    },

    route(path) {
      this.$router.push({
        path: path,
      });
    },
    kjlData() {
      const appkey = "Jj6sgIVmdW";
      const timestamp = new Date().getTime();
      const appsecret = "BRqE1LH6fSZiqCHTN3CaxM2G0QAw3GlY";
      const appuid = "3FO4KDXV5WIS";
      const sign = md5(appsecret + appkey + appuid + timestamp);
      // axios.post('https://openapi.kujiale.com/v2/design/list?start=0&num=50&sort=0&appuid=3FO4KDXV5WIS&time=1560528486000&='+this.sign).then(res=>{
      //   this.kjl=res.data;
      // })

      // .catch(error=>{
      //   console.error('获取数据失败：',error);
      // })

      const apiUri = "/abc/v2/design/list/";
      // 拼接完整的请求URL
      const uri =
        apiUri +
        "?appkey=" +
        appkey +
        "&timestamp=" +
        timestamp +
        "&sign=" +
        sign +
        "&appuid=3FO4KDXV5WIS";
      // 发起请求
      axios({
        method: "get",
        url: uri,
        data: {
          start: 0,
          num: 50,
          sort: 0,
          appuid: "3FO4KDXV5WIS",
          time: timestamp,
        },
      })
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.kjlData();
  },
};
</script>

<style>
.headergyds3 ul li a a{
  position: relative;
  top: -16px;
  left: 40px;
  width: 100px;
  cursor: pointer;
  display: block;
  line-height: 0;
  height:20px;
}
.headergyds3 ul li a {
  cursor: pointer;
  display: block;
  line-height: 0;
}

.headergyds3 ul li img {
  width: 179px;
  height: 155px;
  cursor: pointer;
  margin-right: 33px;
  margin-top: 40px;
}

.headergyds3 ul li {
  padding: 0;
}

.headergyds3 ul {
  margin-left: 425px;
}

.headergyds3 {
  position: fixed;
  left: 0;
  top: 100px;
  height: 230px;
  width: 1920px;
  background-image: url("https://www.d-shang.com/image/xgw/kjlg/bj.png");
  display: none;
}

.headergyds3li:hover .headergyds3 {
  display: block;
}

.headergyds2 ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds2 ul {
  margin-left: 913px;
}

.headergyds2 {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url("https://www.d-shang.com/image/xgw/sy/sy13.png");
  display: none;
}

.headergyds2li:hover .headergyds2 {
  display: block;
}

.headergyds ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds ul {
  margin-left: 675px;
}

.headergyds {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url("https://www.d-shang.com/image/xgw/sy/sy13.png");
  display: none;
}

.headergydsli:hover .headergyds {
  display: block;
}

.hoverli1:hover {
  color: #cbad65;
  border: 0;
}

.a11:hover {
  color: #cbad65;
  border: 0;
}

a {
  text-decoration: none;
}

.login {
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.login a {
  color: #ffffff;
  display: inline-block;
  line-height: 26px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 18px;
  width: 80px;
  font-size: 14px;
}

ul {
  display: flex;
  margin-right: -200px;
}

ul li {
  list-style-type: none;
  color: #fff;
  padding: 0 50px 0 50px;
  font-size: 15px;
  height: 100px;
  /* background: chartreuse; */
}

ul li a {
  color: #ffffff;
  display: inline-block;
  height: 97px;
  cursor: pointer;
  line-height: 100px;
  /* transition:all 250ms linear; */
}

li a:hover {
  color: #cbad65;
  border-bottom: 3px solid #cbad65;
}

.block {
  margin-top: 100px;
}

.header {
  height: 100px;
  width: 100%;
  background: #000000;
  display: flex;
  /* justify-content:space-between; */
  justify-content: space-around;
  /* line-height:100px; */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header .img1 {
  width: 236px;
  height: 35px;
  display: inline-block;
  margin-left: -80px;
}

.header .img2 {
  width: 23px;
  height: 26px;
  margin-top: 11px;
}
</style>